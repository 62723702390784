import React from "react";
import stickybits from "stickybits";

import "./sticky-container.scss";
interface Props {
  children?: any;
  className?: string;
  setElement?: (element: Element) => any;
}

class StickyContainer extends React.Component {
  static defaultProps: Props = {
    className: "",
  };
  stickyInstance: any = null;
  constructor(public props: Props) {
    super(props);
    this.setRef = this.setRef.bind(this);
  }

  componentWillUnmount() {
    if (this.stickyInstance) {
      this.stickyInstance.cleanup();
    }
  }

  componentDidMount() {}
  setRef(element: HTMLElement) {
    if (!this.stickyInstance) {
      const stickyChildren = element.querySelectorAll("[data-sticky]");
      this.stickyInstance = stickybits(stickyChildren, {
        stickyBitStickyOffset: 80,
      });
    }
    if (this.props.setElement) {
      this.props.setElement(element);
    }
  }
  render() {
    return (
      <div
        className={"sticky-container " + this.props.className}
        ref={this.setRef}
      >
        {this.props.children}
      </div>
    );
  }
}

export default StickyContainer;
